import { useRouter } from 'next/router';
// Helpers
import { facebookLink, twitterLink } from 'helpers/social-share';

type SocialLinks = {
  facebookShareLink: string;
  twitterShareLink: string;
};

export const useGetSocialLinks = (options?: {
  url?: string;
  twitterText?: string;
}): SocialLinks => {
  const router = useRouter();
  const { url, twitterText } = options || {};

  const facebookShareLink = facebookLink(url || router.asPath);
  const twitterShareLink = twitterLink(url || router.asPath, twitterText);

  return {
    facebookShareLink,
    twitterShareLink,
  };
};
