// Helpers
import { getEnvLink } from 'helpers/routes';

export type FacebookParams = {
  display?: 'page' | 'popup';
};

type CreateLink<Params = Record<string, unknown>> = (
  path: string,
  params?: Params
) => string;

export const facebookLink: CreateLink<FacebookParams> = (
  path,
  { display = 'page' } = {}
) =>
  `https://www.facebook.com/sharer.php?display=${display}&u=${getEnvLink()}${path}`;

export const twitterLink = (path: string, text: string | undefined): string =>
  `https://twitter.com/intent/tweet?url=${getEnvLink()}${path}${
    text ? `&text=${encodeURIComponent(text)}` : ''
  }`;
