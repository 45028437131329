import React, { useEffect } from 'react';
import cn from 'classnames';
import { Modal, ModalProps } from 'react-responsive-modal';
// Components
import Button, { ButtonColor, ButtonIcon } from 'ui2/Button/Button';

import 'react-responsive-modal/styles.css';
import styles from './NotificationModal.module.scss';

const notificationTypeIcons: {
  [k in NotificationModalProps['type']]: string;
} = {
  success: '/images/notification-modal/success.svg',
  error: '/images/notification-modal/error.svg',
  confirm: '/images/notification-modal/confirm.svg',
};

export type NotificationModalTextContent = {
  highlightedTitle?: string;
  title: string;
  description?: string | React.ReactElement;
} | null;

type NotificationModalProps = {
  open: boolean;
  type: 'success' | 'error' | 'confirm';
  onClose: () => void;
  loading?: boolean;
  acceptLabel?: string;
  acceptButtonColor?: ButtonColor;
  acceptButtonIcon?: ButtonIcon;
  acceptDisabled?: boolean;
  onAcceptClick?: () => void;
  withCancelButton?: boolean;
  onCancelClick?: () => void;
  cancelLabel?: string;
  cancelButtonColor?: ButtonColor;
  cancelButtonIsDisabled?: boolean;
  withoutTitleImage?: boolean;
  modalFooter?: React.ReactElement;
} & NotificationModalTextContent;

const NotificationModal: React.FC<NotificationModalProps> = ({
  open,
  type,
  highlightedTitle,
  title,
  description,
  onClose,
  acceptLabel = 'Accept',
  onAcceptClick,
  acceptButtonColor = type === 'confirm' ? 'maximum-red' : 'black',
  loading = false,
  acceptDisabled = loading,
  withCancelButton = false,
  onCancelClick = onClose,
  cancelLabel = 'Cancel',
  cancelButtonColor = 'black',
  withoutTitleImage,
  children,
  modalFooter,
  acceptButtonIcon,
}) => {
  const classNames: ModalProps['classNames'] = {
    overlay: styles.overlay,
    modal: styles.modal,
    closeButton: styles.closeButton,
  };

  const img = notificationTypeIcons[type];

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && onAcceptClick) {
        onAcceptClick();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onAcceptClick]);

  return (
    <Modal classNames={classNames} open={open} onClose={onClose} center>
      <div className={styles.modalContent}>
        <>
          {!withoutTitleImage && (
            <div className={styles.imageWrapper}>
              <img
                width="140"
                height="140"
                src={img}
                className={styles.image}
                alt={`${type} message`}
                loading="lazy"
              />
            </div>
          )}
          <h3
            className={cn(styles.title, {
              [styles.titleWithoutImageAbove]: withoutTitleImage,
            })}
          >
            {highlightedTitle && (
              <span
                className={cn(styles.highlighted, { [styles[type]]: !!type })}
              >
                {highlightedTitle}
              </span>
            )}
            {title}
          </h3>
          {description && <p className={styles.description}>{description}</p>}
          {children && <div className={styles.childrenWrapper}>{children}</div>}
          <div className={styles.actions}>
            {withCancelButton && (
              <Button
                xs="default"
                color={cancelButtonColor}
                className={styles.button}
                onClick={onCancelClick}
              >
                {cancelLabel}
              </Button>
            )}
            {Boolean(onAcceptClick) && (
              <Button
                xs="default"
                endIcon={acceptButtonIcon}
                color={acceptButtonColor}
                className={styles.button}
                onClick={onAcceptClick}
                loading={loading}
                disabled={acceptDisabled || loading}
              >
                {acceptLabel}
              </Button>
            )}
          </div>
          {modalFooter}
        </>
      </div>
    </Modal>
  );
};

export default NotificationModal;
