import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
// Hooks
import { useBreakpoints } from 'hooks';
// Styles
import styles from './Title.module.scss';

type TitleProps = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> & {
  level?: number;
  color?: string;
  textAlign?: 'center' | 'right';
  uppercase?: boolean;
  lowercase?: boolean;
  bold?: boolean;
};

const Title = ({
  level = 1,
  color = 'white',
  textAlign,
  uppercase,
  lowercase,
  bold,
  className,
  ...rest
}: TitleProps) => {
  const { isBreakpointM } = useBreakpoints();

  const TitleTag = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  const titleSize = isBreakpointM
    ? ['5x-large', '4x-large', '3x-large', '2x-large', 'extra-large', 'large']
    : ['2x-large', '4x-large', '3x-large', 'extra-large', 'large', 'large'];

  return (
    <TitleTag
      className={cn(
        styles.title,
        {
          [styles[`align-${textAlign}`]]: textAlign,
          [styles[`color-${color}`]]: color,
          [styles.uppercase]: uppercase,
          [styles.lowercase]: lowercase,
          [styles.bold]: bold,
        },
        styles[`size-${titleSize[level - 1]}`],
        className
      )}
      {...rest}
    />
  );
};

export default Title;
