import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './RadioButton.module.scss';

type RadioButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  labelClassName?: string;
  boldLabel?: boolean;
  onClick?: (any) => void;
};

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    { name, label, labelClassName, boldLabel, className, onClick, ...rest },
    ref
  ) => {
    return (
      <div className={cn(styles.radioButtonContainer, className)}>
        <input
          className={styles.radioButtonInput}
          ref={ref}
          name={name}
          id={name}
          type="checkbox"
          onClick={onClick}
          {...rest}
        />

        <label
          htmlFor={name}
          className={cn(
            styles.radioButtonLabel,
            { [styles.bold]: boldLabel },
            labelClassName
          )}
          onClick={onClick}
          onKeyPress={onClick}
          role="presentation"
        >
          {label}
        </label>
      </div>
    );
  }
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;
