import { Modal, ModalProps } from 'react-responsive-modal';
// Ui2
import Text from 'ui2/Text/Text';
import Title from 'ui2/Title/Title';
import Button, { ButtonColor, ButtonIcon } from 'ui2/Button/Button';
// Styles
import 'react-responsive-modal/styles.css';
import styles from './NotificationModal.module.scss';

const notificationTypeIcons: {
  [k in NotificationModalProps['type']]: string;
} = {
  success: '/images/notification-modal/success.svg',
  error: '/images/notification-modal/error.svg',
  confirm: '/images/notification-modal/confirm.svg',
};

export type NotificationModalTextContent = {
  highlightedTitle?: string;
  title: string;
  description?: string | JSX.Element;
} | null;

type NotificationModalProps = {
  open: boolean;
  type: 'success' | 'error' | 'confirm';
  onClose: () => void;
  loading?: boolean;
  acceptLabel?: string;
  acceptButtonColor?: ButtonColor;
  acceptButtonIcon?: ButtonIcon;
  acceptDisabled?: boolean;
  onAcceptClick?: () => void;
  withCancelButton?: boolean;
  onCancelClick?: () => void;
  cancelLabel?: string;
  cancelButtonColor?: ButtonColor;
  cancelButtonIsDisabled?: boolean;
  withoutTitleImage?: boolean;
  modalFooter?: JSX.Element;
  children?: JSX.Element;
} & NotificationModalTextContent;

const NotificationModal = ({
  open,
  type,
  highlightedTitle,
  title,
  description,
  onClose,
  acceptLabel = 'Accept',
  onAcceptClick,
  acceptButtonColor = type === 'confirm' ? 'maximum-red' : 'god-grey',
  loading = false,
  acceptDisabled = loading,
  withCancelButton = false,
  onCancelClick = onClose,
  cancelLabel = 'Cancel',
  cancelButtonColor = 'god-grey',
  withoutTitleImage,
  children,
  modalFooter,
  acceptButtonIcon,
}: NotificationModalProps): JSX.Element => {
  const classNames: ModalProps['classNames'] = {
    overlay: styles.overlay,
    modal: styles.modal,
    closeButton: styles.closeButton,
    closeIcon: styles.closeIcon,
  };

  const img = notificationTypeIcons[type];

  return (
    <Modal classNames={classNames} open={open} onClose={onClose} center>
      <div className={styles.modalContent}>
        <>
          {!withoutTitleImage && (
            <div className={styles.imageWrapper}>
              <img
                width="140"
                height="140"
                src={img}
                className={styles.image}
                alt={type}
                loading="lazy"
              />
            </div>
          )}

          <Title
            level={3}
            textAlign="center"
            lowercase
            className={styles.title}
            color="black"
          >
            {highlightedTitle && (
              <Text
                lowercase
                xs="3x-large"
                textAlign="center"
                color={
                  type === 'success'
                    ? 'chateau-green'
                    : type === 'error'
                    ? 'maximum-red'
                    : 'harvest-gold'
                }
                className={styles.highlighted}
              >
                {highlightedTitle}
              </Text>
            )}

            {title}
          </Title>

          {description && (
            <Text color="black" textAlign="center">
              {description}
            </Text>
          )}

          {children && <div className={styles.childrenWrapper}>{children}</div>}

          <div className={styles.actions}>
            {withCancelButton && (
              <Button
                color={cancelButtonColor}
                className={styles.button}
                onClick={onCancelClick}
              >
                {cancelLabel}
              </Button>
            )}
            {Boolean(onAcceptClick) && (
              <Button
                endIcon={acceptButtonIcon}
                color={acceptButtonColor}
                className={styles.button}
                onClick={onAcceptClick}
                loading={loading}
                disabled={acceptDisabled || loading}
              >
                {acceptLabel}
              </Button>
            )}
          </div>

          {modalFooter}
        </>
      </div>
    </Modal>
  );
};

export default NotificationModal;
